<template>
  <div>
    <c-search-box @enter="getSearchList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-table
          ref="processTable"
          title="공사형태"
          :columns="grid.columns"
          :data="grid.data"
          :isFullScreen="false"
          :columnSetting="false"
          :filtering="false"
          :usePaging="false"
          :isExcelDown="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline>
              <c-btn label="검색" icon="search" @btnClicked="getSearchList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-tab
          type="tabcard"
          :tabItems.sync="tabItems"
          :inlineLabel="true"
          v-model="tab"
          align="left"
        >
          <template v-slot:default="tab">
            <component
              v-if="tab.name == 'processDetail'"
              ref="processDetail"
              :is="tab.component"
              @searchDetail="getProcessList"
              @isNew="reset"
              :parentProcessCd="selectedProcessCd"
            />
            <component
              v-else
              ref="processUnit"
              :is="tab.component"
              @searchDetail="getProcessList"
              @isNew="reset"
              :parentProcess="selectedProcess"
            />
          </template>
        </c-tab>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'process-manage',
  data() {
    return {
      tab: 'processDetail',
      tabItems: [
        {
          name: 'processDetail',
          icon: 'groups',
          label: '공사형태 상세',
          component: () => import(`${'./processDetail.vue'}`),
        },
        {
          name: 'processUnit',
          icon: 'list',
          label: '공사형태별 공종',
          component: () => import(`${'./processUnit.vue'}`),
        },
      ],
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        processLevelCd: '10',
      },
      grid: {
        columns: [
          {
            name: 'constructTypeName',
            field: 'constructTypeName',
            label: '공사형태',
            align: 'left',
            sortable: true,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: "useFlag",
            field: "useFlag",
            label: "사용여부",
            align: "center",
            style: "width: 70px",
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      selectedProcessCd: '',
      selectedProcess: {
        constructTypeCd: null
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.construct.list.url;
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getSearchList() {
      this.getList();
      this.reset();
    },
    getProcessList() {
      this.getList();
      if (this.selectedProcessCd == '') {
        this.reset();
      }
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        if (this.selectedProcessCd != '') {
          this.$refs['processDetail'].rowClick({
            processCd: this.selectedProcessCd,
          });
        } else {
          if (this.$refs["processDetail"] !== undefined) {
            this.$refs["processDetail"].reset();
          }
        }
      });
    },
    rowClick(row) {
      // 상세조회
      this.selectedProcess = row;
      this.selectedProcessCd = row.constructTypeCd;
    },
    reset() {
      this.rowRemoveSelect();
      this.selectedProcessCd = '';
      this.selectedProcess = null;
    },
  },
};
</script>
